import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Gráficos e Relatórios em Tempo Real',
    description:
      'Visualize o desempenho da sua ótica com gráficos detalhados e relatórios que te ajudam a tomar decisões rápidas e baseadas em dados concretos.',
    imageLight: 'url("../assets/images/dashboard.png")',
    imageDark: 'url("../assets/images/dashboard.png")',
    width: 580,
    height: 400,
  },
  {
    icon: <ShoppingBagIcon />,
    title: 'Gestão completa e eficiente',
    description:
      'Simplifique o gerenciamento de vendas, estoque e clientes. Acesse tudo de forma fácil e organizada, a qualquer momento, em qualquer dispositivo.',
    imageLight: 'url("../assets/images/vendas.png")',
    imageDark: 'url("../assets/images/vendas.png")',
    width: 490,
    height: 400,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: ' Multiusuário e Controle de Acesso',
    description:
      'Gerencie várias filiais de forma centralizada, com acessos personalizados para cada colaborador, tudo em uma única plataforma.',
    imageLight: 'url("../assets/images/PointOfSale.png")',
    imageDark: 'url("../assets/images/PointOfSale.png")',
    width: 560,
    height: 350,
  },
];

export function MobileLayout() {
  return (
    <Box id="features"
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {items.map(item => (
        <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundImage: 'var(--items-imageLight)',
            ...theme.applyStyles('dark', {
              backgroundImage: 'var(--items-imageDark)',
            }),
          })}
          style={
            item
              ? ({
                '--items-imageLight': item.imageLight,
                '--items-imageDark': item.imageDark,
              } as any)
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
          >
            {item.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
            {item.description}
          </Typography>
        </Box>
      </Card>
      ))}
    </Box>
  );
}

export default function Features() {
  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: '100%', md: '100%' }, textAlign: 'center' }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Recursos do sistema
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
        >
          Controle suas vendas de forma digital e de qualquer lugar, podendo gerenciar toda sua rede de lojas.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'column' },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 2,
              height: '100%',
            }}
          >
            {items.map((item, index) => (
              <Box
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: '100%',
                    width: '100%',
                  }),
                ]}
              >
                {index % 2 == 1 ? <><Box
                  sx={[
                    {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      gap: 1,
                      textAlign: 'left',
                      textTransform: 'none',
                      color: 'text.secondary',
                    },
                    true && {
                      color: 'text.primary',
                    },
                  ]}
                >
                  {item.icon}

                  <Typography variant="h5">{item.title}</Typography>
                  <Typography variant="body1">{item.description}</Typography>
                </Box>
                  <Card
                    variant="outlined"
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: { xs: 'none', sm: 'flex' },
                      pointerEvents: 'none',
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        m: 'auto',
                        width: item.width ?? 580,
                        height: item.height ?? 400,
                        backgroundSize: 'cover',
                        backgroundImage: 'var(--items-imageLight)',
                        ...theme.applyStyles('dark', {
                          backgroundImage: 'var(--items-imageDark)',
                        }),
                      })}
                      style={
                        item
                          ? ({
                            '--items-imageLight': item.imageLight,
                            '--items-imageDark': item.imageDark,
                          } as any)
                          : {}
                      }
                    />
                  </Card></> : <>
                  <Card
                    variant="outlined"
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: { xs: 'none', sm: 'flex' },
                      pointerEvents: 'none',
                      margin: 2
                    }}
                  >
                    <Box
                      sx={(theme) => ({
                        m: 'auto',
                        width: item.width ?? 580,
                        height: item.height ?? 400,
                        backgroundSize: 'cover',
                        backgroundImage: 'var(--items-imageLight)',
                        ...theme.applyStyles('dark', {
                          backgroundImage: 'var(--items-imageDark)',
                        }),
                      })}
                      style={
                        item
                          ? ({
                            '--items-imageLight': item.imageLight,
                            '--items-imageDark': item.imageDark,
                          } as any)
                          : {}
                      }
                    />
                  </Card><Box
                    sx={[
                      {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        gap: 1,
                        textAlign: 'left',
                        textTransform: 'none',
                        color: 'text.secondary',
                      },
                      true && {
                        color: 'text.primary',
                      },
                    ]}
                  >
                    {item.icon}

                    <Typography variant="h5">{item.title}</Typography>
                    <Typography variant="body1">{item.description}</Typography>
                  </Box>
                </>}

              </Box>
            ))}
          </Box>
          <MobileLayout />
        </div>
      </Box>
    </Container>
  );
}
