import { useTheme } from '@mui/material';
import darkLogo from '../assets/logos/OticaSystems_full_branco.png'
import whiteLogo from '../assets/logos/OticaSystems_Resolution.png'


export default function SitemarkIcon() {
  const theme = useTheme();
  const logo = theme.palette.mode === 'light' ? whiteLogo : darkLogo;
  return (
    <img src={logo} width={150}/>
  );
}
